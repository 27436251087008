<template>
  <v-card
    outlined
    tile
    height="100px"
    width="100px"
  >
    <v-img
      :src="require('@/assets/images/docDefault.png')"
      class="white--text align-start"
      height="40px"
      width="100px"
    >
    
        <v-checkbox
          v-model="innerValue"
          dense
          outlined
          :value="doc.id"
          v-bind="$attrs"
          v-on="$listeners"
        />
     
    </v-img>
    <v-card-text class="text-caption pa-2">
      <p class="min-line-height">
        <strong>{{ truncateText }}</strong>
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    doc: {
      type: Object,
      default: () => {}
    },
    value: {
      type: null
    }
  },
  data() {
    return {
      innerValue: false
    }
  },
  computed: {
    truncateText() {
      const docName = this.doc.nombre_documento
      return docName.length > 29 ? `${docName.substring(0, 29)}` : docName
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal;
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
}
</script>

<style scoped>
.min-line-height {
  line-height: 1.2;
}
</style>