<template>
  <v-container
    fluid
    style="max-height: 200px"
    class="overflow-y-auto"
  >
    <v-row
      v-if="docs.length"
      dense
    >
      <v-col 
        v-for="doc in docs"
        :key="doc.id"
        :cols="cols"
      >
        <kn-docs-gallery-item
          v-model="docsSelected"
          :doc="doc"
          v-bind="$attrs"
          v-on="$listeners"
        />
      </v-col>
    </v-row>
    <v-alert
      v-else
      dense
      outlined
      type="info"
    >
      Aún no hay documentos en la galería
    </v-alert>
  </v-container>
</template>

<script>
import KnDocsGalleryItem from './KnDocsGalleryItem.vue';
export default {
  components: { KnDocsGalleryItem },
  props: {
    docs: {
      type: Array,
      default: () => []
    },
    cols: {
      type: [String, Number],
      default: '2'
    },
    value: {
      type: null
    }
  },
  data() {
    return {
      docsSelected: []
    }
  },
  watch: {
    // Handles internal model changes.
    docsSelected (newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value (newVal) {
      this.docsSelected = newVal;
    }
  },
  created () {
    if (this.value) {
      this.docsSelected = this.value;
    }
  }
}
</script>

<style>

</style>