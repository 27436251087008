<template>
  <v-container
    fluid
    style="max-height: 200px"
    class="overflow-y-auto"
  >
    <v-row
      v-if="images.length"
      dense
    >
      <v-col 
        v-for="image in images"
        :key="image.id"
        :cols="colsImage"
      >
        <kn-images-gallery-item
          v-model="imagesSelected"
          :image="image"
          v-bind="$attrs"
          v-on="$listeners"
        />
      </v-col>
    </v-row>
    <v-alert
      v-else
      dense
      outlined
      type="info"
    >
      Aún no hay imágenes en la galería
    </v-alert>
  </v-container>
</template>

<script>
import KnImagesGalleryItem from './KnImagesGalleryItem.vue';
export default {
  components: { KnImagesGalleryItem },
  props: {
    images: {
      type: Array,
      default: () => []
    },
    cols: {
      type: [String, Number],
      default: '2'
    },
    value: {
      type: null
    }
  },
  data() {
    return {
      imagesSelected: []
    }
  },
  computed: {
    colsImage() {
      return this.$vuetify.breakpoint.mobile ? '6' : this.cols
    }
  },
  watch: {
    // Handles internal model changes.
    imagesSelected (newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value (newVal) {
      this.imagesSelected = newVal;
    }
  },
  created () {
    if (this.value) {
      this.imagesSelected = this.value;
    }
  }
}
</script>

<style>

</style>