<template>
  <v-card
    outlined
    tile
    height="100px"
    width="100px"
  >
    <v-img
      :src="image.imagen"
      class="white--text align-start"
      height="100px"
      width="100px"
    >
    
        <v-checkbox
          v-model="innerValue"
          dense
          outlined
          :value="image.id"
          v-bind="$attrs"
          v-on="$listeners"
        />
     
    </v-img>
  </v-card>
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
      default: () => {}
    },
    value: {
      type: null
    }
  },
  data() {
    return {
      innerValue: false
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal;
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
}
</script>

<style>

</style>