<template>
  <section>
    <v-container>
      <v-row
        class="pt-15"
        justify="center"
        align="center"
      >
        <v-col
          cols="11"
          md="8"
          lg="8"
          xl="5"
        >
          <kn-form-title
            :title="title"
            :routerName="routerName"
          />
          <kn-tabs
            :items="tabs"
            @tabValue="setTabValue"
          />
          <kn-form-note-of-mandatory />
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <!-- Inicio información básica de productos -->
            <div v-if="valueDeterminate === 33">
              <kn-text-field
                class="mt-4"
                label="SKU:"
                v-model="product.sku"
                :rules="[rules.required]" 
              />
              <kn-text-field
                label="Descripción corta:"
                v-model="product.descripcion_corta"
                :rules="[rules.required]" 
              />
              <kn-text-area
                label="Descripción larga:"
                v-model="product.descripcion_larga"
                :rules="[rules.required]"
              />
              <kn-text-field
                label="Nombre de producto:"
                v-model="product.nombre_producto"
                :rules="[rules.required]" 
              />
              <kn-text-field
                label="Precio sugerido:"
                v-model="product.msrp"
                :rules="[rules.required]" 
              />
              
              <v-row>
                <v-col
                  class="pt-0 pb-5"
                  cols="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <v-subheader>Calificación:</v-subheader>
                </v-col>
                <v-col
                  class="pt-0 pb-5 pr-0"
                  cols="10"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <v-rating
                    v-model="product.calificacion"
                    background-color="warning lighten-1"
                    color="warning"
                    half-increments
                    hover
                    length="5"
                    size="32"
                  />
                  
                </v-col>
                <v-col class="pt-0 pb-5 px-0">
                  <div class="grey--text text--lighten text-caption pt-3">
                    ({{ product.calificacion }})
                  </div>
                </v-col>
              </v-row>

              <kn-text-field
                label="Prioridad display:"
                v-model="product.prioridad_display"
                :rules="[]"
                :isMandatory="false"
              />
              <kn-text-field
                label="Peso volumétrico:"
                v-model="product.peso_volumetrico"
                :rules="[rules.required]"
              />
              <kn-check-box
                label="Destacado:"
                v-model="product.destacado"
                :rules="[]"
                :isMandatory="false"
              />
              <kn-check-box
                label="Cuarentena:"
                v-model="product.cuarentena"
                :rules="[]"
                :isMandatory="false"
              />
              <kn-select
                label="Marca:"
                v-model="product.id_marca"
                :rules="[rules.required]"
                :items="activeItems(brands)"
                item-value="id"
                item-text="dato"
              />

              <v-row>
                <v-col
                  class="py-0"
                  cols="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <v-subheader>Código SAT:</v-subheader>
                </v-col>
                <v-col class="py-0">
                  <v-autocomplete
                    v-model="product.id_codigo_sat"
                    dense
                    outlined
                    :rules="[rules.required]"
                    :items="activeItems(satCodes)"
                    item-value="id"
                    item-text="descripcion"
                  >
                    <template #selection="{ item }">
                      {{ item.dato }} -  {{ item.descripcion }}
                    </template>
                    <template #item="{ item }">
                      {{ item.dato }} -  {{ item.descripcion }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            
              <kn-select
                label="Unidad de medida:"
                v-model="product.id_unidad_medida"
                :rules="[rules.required]"
                :items="activeItems(measurementUnits)"
                item-value="id"
                item-text="dato"
              />
              <kn-select
                label="Categoría:"
                v-model="product.id_categoria"
                :rules="[rules.required]"
                :items="activeItems(categories)"
                item-value="id"
                item-text="dato"
              />
              <kn-select
                label="Sub categoría:"
                v-model="product.id_subcategoria"
                :rules="[rules.required]"
                :items="activeItems(subcategories)"
                item-value="id"
                item-text="dato"
              />
              <kn-select
                label="Backorder:"
                v-model="product.id_backorder"
                :rules="[rules.required]"
                :items="activeItems(backorders)"
                item-value="id"
                item-text="nombre"
              />
            </div>
            <!-- Fin información básica de productos -->

            <!-- Inicio información complementaria de productos -->
            <div v-if="valueDeterminate === 66">
              <kn-text-field
                class="mt-4"
                label="Precio:"
                v-model.number="distributionPrice"
                :rules="[]"
              />
              <kn-select
                label="Moneda:"
                v-model="product.id_moneda"
                :rules="[]"
                :items="activeItems(coins)"
                item-value="id"
                item-text="nombre_moneda"
              />
              <kn-select
                label="Impuestos:"
                v-model="taxesSelected"
                multiple
                :rules="[]"
                :items="activeItems(taxes)"
                item-value="id"
                item-text="nombre_impuesto"
              />
              <kn-select
                label="Opciones de producto:"
                v-model="optionsSelected"
                multiple
                :rules="[]"
                :items="activeItems(options)"
                item-value="id"
                item-text="nombre_opcion"
              />
              <kn-form-subtitle title="Imágenes de producto" />
              <v-row class="mt-10 mb-5">
                <v-col class="py-0">
                  <kn-images-gallery-container
                    v-model="imagesSelected"
                    :images="images"
                  />
                </v-col>
              </v-row>
              <kn-doc-item
                class="mt-6 mb-10"
                idInput="inputImages"
                label="Agregar imágenes:"
                :multiple="true"
                @saveFile="saveImagesSelected"
                @clearFile="clearImages"
              />

              <kn-form-subtitle title="Documentos de producto" />
              <v-row class="mt-10 mb-5">
                <v-col class="py-0">
                  <kn-docs-gallery-container
                    v-model="docsSelected"
                    :docs="docs"
                  />
                </v-col>
              </v-row>
              <kn-doc-item
                class="mt-6 mb-10"
                idInput="inputDocumentos"
                label="Agregar documentos:"
                :multiple="true"
                @saveFile="saveDocsSelected"
                @clearFile="clearDocs"
              />
            </div>
            <!-- Fin información complementaria de productos -->
            <!-- Inventario -->
            <div v-if="valueDeterminate === 100">
              <kn-select
                class="mt-4"
                label="Opción de producto:"
                v-model="inventory.id_opcion_producto"
                :rules="[]"
                :items="activeItems(options)"
                item-value="id"
                item-text="nombre_opcion"
                :disabled="hasInventory"
              />
              <kn-select
                label="Almacén:"
                v-model="inventory.id_almacen"
                :rules="[]"
                :items="activeItems(warehouseArray)"
                item-value="id"
                item-text="nombre_almacen"
                :disabled="hasInventory"
              />
              <kn-text-field
                class="mt-4"
                label="Cantidad:"
                v-model.number="inventory.cantidad_disponible"
                :rules="[]"
                :disabled="hasInventory"
              />
              <kn-text-field
                class="mt-4"
                label="Precio unitario:"
                v-model.number="distributionPrice"
                :rules="[]"
                :disabled="hasInventory"
              />
              <kn-select
                label="Moneda:"
                v-model="product.id_moneda"
                :rules="[]"
                :items="activeItems(coins)"
                item-value="id"
                item-text="nombre_moneda"
              />
              <kn-select
                label="Unidad de medida:"
                v-model="inventory.id_unidad_medida"
                :rules="[]"
                :items="activeItems(measurementUnits)"
                item-value="id"
                item-text="dato"
                :disabled="hasInventory"
              />
              <kn-text-area 
                label="Comentarios"
                v-model="inventory.comentarios"
                :rules="[]"
                :disabled="hasInventory"
              />
              <template  v-if="hasInventory">
                <kn-form-subtitle
                  title="Actualizar inventario"
                />
                <kn-text-field
                  class="mt-4"
                  label="Cantidad a agregar:"
                  v-model.number="amountToAdd"
                  :rules="[]"
                />
              </template>
            </div>
            <!-- Fin inventario -->
          </v-form>
          <kn-form-action-buttons
            @accept="save()"
            @cancel="cancel()"
          />
        </v-col>
      </v-row>
    </v-container>
    <kn-back-to-top-button />
    <kn-local-alert
      v-model="showAlert"
      :alertText="alertText" 
      :alertType="alertType"
      :alertColor="alertColor"
      :loadingAlert="loading"
      @action1="actionAlertBtn1"
      @action2="continueAdding"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import KnFormActionButtons from '@/components/KnFormActionButtons.vue'
import KnLocalAlert from '@/components/KnLocalAlert.vue'
import KnTabs from '@/components/KnTabs.vue'
import KnTextField from '@/components/inputs/KnTextField.vue'
import KnTextArea from '@/components/inputs/KnTextArea.vue'
import KnCheckBox from '@/components/inputs/KnCheckBox.vue'
import KnSelect from '@/components/inputs/KnSelect.vue'
import KnDocItem from '@/components/forms/KnDocItem.vue'
import { fileUtilsMixin } from '@/mixins/fileUtilsMixin'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { apiMixin } from '@/mixins/apiMixin'
import KnImagesGalleryContainer from '@/components/images_gallery/KnImagesGalleryContainer.vue'
import KnDocsGalleryContainer from '@/components/documents_gallery/KnDocsGalleryContainer.vue'
import KnFormSubtitle from '@/components/KnFormSubtitle.vue'
import KnBackToTopButton from '@/components/forms/KnBackToTopButton.vue'
import KnFormTitle from '@/components/KnFormTitle.vue'
import KnFormNoteOfMandatory from '@/components/KnFormNoteOfMandatory.vue'
export default {
  components: {
    KnFormActionButtons,
    KnLocalAlert,
    KnTabs,
    KnTextField,
    KnTextArea,
    KnCheckBox,
    KnSelect,
    KnDocItem,
    KnImagesGalleryContainer,
    KnDocsGalleryContainer,
    KnFormSubtitle,
    KnBackToTopButton,
    KnFormTitle,
    KnFormNoteOfMandatory
  },
  mixins: [
    fileUtilsMixin,
    validationFormMixin,
    apiMixin
  ],
  props: {
    entity: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      tabs: [
        { name: 'Información básica', value: 33 },
        { name: 'Información complementaria', value: 66 },
        { name: 'Inventario', value: 100 },        
      ],
      valueDeterminate: 33,
      routerName: 'Productos',
      valid: true,
      productId: null,
      product: {
        prioridad_display: 0,
        calificacion: 0,
        destacado: false,
        cuarentena: false
      },
      inventory: {
        id_opcion_producto: null,
        id_almacen: null,
        cantidad_disponible: null,
        id_unidad_medida: null,
        comentarios: null,
        fecha_ultima_actualizacion: new Date(),
        id_autor: null,
        id_institucion_educativa: null
      },
      movement: {},
      brands: [],
      satCodes: [],
      measurementUnits: [],
      categories: [],
      subcategories: [],
      backorders: [],
      coins: [],
      taxes: [],
      options: [],
      warehouseArray: [],
      images: [],
      docs: [],
      movementTypes: [],
      movementReasons: [],
      distributionPrice: null,
      distributionPriceObj: null,
      purchasePrice: null,
      amountToAdd: null,
      taxesSelected: [],
      bkTaxesSelected: [],
      optionsSelected: [],
      bkOptionsSelected: [],
      imagesSelected: [],
      bkImagesSelected: [],
      docsSelected: [],
      bkDocsSelected: [],

      imagesObj: null,
      docsObj: null,
      photoDoc: null,

      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /************************ */
    }
  },
  ///
  /// ==================================
  /// AUTOR: Tania Lucero Marin Sosa
  /// RESEÑANTE: Arturo López López
  /// FECHA: 17/05/2022
  /// COMENTARIOS:
  /// - Se revisó comportamiento de inventario cuando se
  ///   actualiza un valor de producto.
  /// - Se agregó bandera !hasInventory a la condición que
  ///   permite que se mande a llamar el updateInventory
  /// - Se agregó método computado para validar el valor
  ///   de la variable amoutToAdd
  /// ==================================
  ///
  computed: {
    ...mapState(['institutionId', 'userData', 'warehouse']),
    title() {
      return this.entity === null ? 'Agregar producto' : 'Editar producto'
    },
    isNewMode() {
      return (this.entity === null)
    },
    successAlertText() {
      return this.isNewMode ? 'Producto registrado con exito!' : 'Producto actualizado con exito!'
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info'
    },
    inventoryIsValid() {
      return !this.objectHasNulls(this.inventory)
    },
    hasInventory() {
      return !this.isNewMode && this.entity.inventario !== null
    },
    amountToAddValid() {
      return this.amountToAdd !== null && this.amountToAdd > 0
    }
  },
  async created() {
    this.loading = true
    this.alertText = 'Por favor, espere. Cargando...'
    this.showAlert = true
    this.alertType = 'info'

    this.brands = await this.fetchResultsByEI('productos', 'marca-producto', this.institutionId)
    this.satCodes = await this.fetchResultsByEI('productos', 'codigos-sat', this.institutionId)
    this.measurementUnits = await this.fetchResultsByEI('productos', 'uom', this.institutionId)
    this.categories = await this.fetchResultsByEI('productos', 'categoria-producto', this.institutionId)
    this.subcategories = await this.fetchResultsByEI('productos', 'subcategoria-producto', this.institutionId)
    this.backorders = await this.fetchResultsByEI('productos', 'backorder', this.institutionId)
    this.coins = await this.fetchResultsByEI('administracion', 'moneda', this.institutionId)
    this.taxes = await this.fetchResultsByEI('productos', 'impuesto', this.institutionId)
    this.options = await this.fetchResultsByEI('productos', 'opciones-producto', this.institutionId)
    this.warehouseArray = await this.fetchResultsByEI('inventarios', 'almacen', this.institutionId)
    this.images = await this.fetchResultsByEI('productos', 'imagen-producto', this.institutionId)
    this.docs = await this.fetchResultsByEI('productos', 'documento-producto', this.institutionId)
    
    if (!this.isNewMode) {
      // console.log('Se deben llenar los campos');
      this.fillData()
    } else {
      this.product.id_institucion_educativa = this.institutionId
      this.product.id_autor = this.userData.id

      this.inventory.id_institucion_educativa = this.institutionId
      this.inventory.id_autor = this.userData.id
      this.inventory.id_almacen = this.warehouse.id
    }
    this.showAlert = false
  },
  methods: {
    async createInventory() {
      try {
        const response = await this.postObj('/inventarios/crear-inventario', {
          id_producto: this.productId,
          id_opcion_producto: this.inventory.id_opcion_producto,
          id_almacen: this.inventory.id_almacen,
          cantidad_disponible: this.inventory.cantidad_disponible,
          id_unidad_medida: this.inventory.id_unidad_medida,
          comentarios: this.inventory.comentarios,
          fecha_ultima_actualizacion: this.inventory.fecha_ultima_actualizacion,
          id_autor: this.inventory.id_autor,
          id_institucion_educativa: this.inventory.id_institucion_educativa
        })
        if (response) {
          this.alertText = 'Inventario creado con exito'
        } else {
          this.warnings.push('No se pudo crear inventario')
        }
      } catch (error) {
        console.error('Error al intentar crear inventario');
      }
    },
    async updateInventory() {
      try {
        const response = await this.postObj('/inventarios/update-inventario', {
          id: this.inventory.id,
          id_producto: this.productId,
          id_opcion_producto: this.inventory.id_opcion_producto,
          id_almacen: this.inventory.id_almacen,
          cantidad_disponible: this.inventory.cantidad_disponible + this.amountToAdd,
          id_unidad_de_medida: this.inventory.id_unidad_medida,
          comentarios: this.inventory.comentarios,
          fecha_ultima_actualizacion: new Date(),
          id_autor: this.inventory.id_autor,
          id_institucion_educativa: this.inventory.id_institucion_educativa
        })
        if (response) {
          this.alertText = 'Inventario actualizado con exito'
        } else {
          this.warnings.push('No se pudo actualizar inventario')
        }
      } catch (error) {
        console.error('Error al intentar crear inventario');
      }
    },
    async createInventoryMovement() {
      try {
        this.movementTypes = await this.fetchResultsAll('inventarios', 'mv-movimiento-inventario-all')
        this.movementReasons = await this.fetchResultsAll('inventarios', 'motivo-movimiento-inventario-all')
        const movementType = this.movementTypes.find(mType => mType.dato === 'Entrada')
        const movementReason = this.movementReasons.find(mReason => mReason.dato === 'Compra')

        const initialAmount = this.hasInventory ? this.inventory.cantidad_disponible : 0
        const amountOfMovement = this.hasInventory ? this.amountToAdd : this.inventory.cantidad_disponible
        const finalAmount = initialAmount + amountOfMovement
        const unitPrice = this.distributionPrice
        const valueOfMovement = unitPrice * amountOfMovement

        const movementObj = {
          id_producto: this.productId,
          id_tipo_movimiento: movementType.id, // Tipo de movimiento: entrada
          id_motivo_movimiento: movementReason.id, // Motivo de movimiento: compra
          id_almacen: this.inventory.id_almacen,
          inventario_inicial: initialAmount,
          cantidad_movimiento: amountOfMovement,
          inventario_final: finalAmount,
          precio_unitario: unitPrice,
          valor_movimiento: valueOfMovement,
          id_moneda: this.product.id_moneda,
          id_autor: this.userData.id,
          id_institucion_educativa: this.institutionId
        }

        const response = await this.postObj('/inventarios/crear-movimiento-inventario', movementObj)

        if (response) {
          this.alertText = 'Movimiento de inventario creado con exito'
        } else {
          this.warnings.push('No se pudo crear movimiento de inventario.')
        }
      } catch (error) {
        console.error('Error al intentar crear movimiento de inventario', error)
        this.errors.push('Error al intentar crear movimiento de inventario')
      }
    },
    async createAndAssignPriceToProduct() {
      try {
        const priceObj = {
          precio_distribucion: this.distributionPrice,
          id_moneda: this.product.id_moneda,
          id_institucion_educativa: this.institutionId,
          id_autor: this.userData.id
        }
        const priceResponse = await this.postObj('/productos/crear-precio-distribuidor', priceObj)

        if (priceResponse !== null) {
            const priceId = priceResponse.id_objeto
            const assignamentResponse = await this.postObj(
              '/productos/add-precio-producto',
              {
                id_precio: priceId,
                id_producto: this.productId
              },
              'asignar'
            )

            if (assignamentResponse) {
              this.alertText = "Precio asignado exitosamente"
            } else {
              this.warnings.push('No se pudo asignar el precio al producto')
            }

        } else {
          this.warnings.push('No se pudo crear el precio')
        }
      } catch (error) {
        console.warn('Error al intentar crear o asignar precio a producto', error);
      }
    },
    async updatePrice() {
      try {
        const priceObj = {
          id: this.distributionPriceObj.id,
          precio_distribuidor: this.distributionPrice,
          id_distribuidor: this.institutionId,
          id_moneda: this.product.id_moneda
        }
        const priceResponse = await this.postObj('/productos/update-precio-distribuidor', priceObj, 'Actualizar')
        if (priceResponse) {
          this.alertText = "Precio actualizado exitosamente"
        } else {
          this.warnings.push('No se pudo actualizar el precio del producto')
        }
      } catch (error) {
        console.error('Error al intentar actualizar precio.', error);
      }
    },
    /** 
     * Este metodo se usará cuando se seleccione subir una
     * imagen en vez de asignar alguna de las ya existentes
    */
    async createAndAssignImagesToProduct() {
      /** Para asignar una imagen a un producto se hace lo siguiente:
       *  Por cada archivo seleccionado
       *  - Subir archivo
       *  - Crear imagen
       *  - Asignar imágen a producto
       * 
       */
      try {
        const images = [...this.imagesObj.file]
        //const names = [...this.imagesObj.fileName]
        for (const imageFile of images) {
          // Subir archivo
          const imageToUpload = {
            file: imageFile
          }
          const uploadResponse = await this.uploadFile(imageToUpload)
          if (uploadResponse.e) {
            this.warnings.push('No pudo subir la imagen ' + uploadResponse.e.join(', '))
          } else {
            // Crear imagen
            const { nombre_archivo, url_archivo } = uploadResponse.archivos[0]
            const imageObj = {
              nombre_imagen: nombre_archivo,
              url_imagen: url_archivo,
              id_institucion_educativa: this.institutionId,
              id_autor: this.userData.id
            }
            const imageResponse = await this.postObj('/productos/crear-imagen-producto', imageObj)

            if (imageResponse) {
              // Asignar imágen a producto
              const imageId = imageResponse.id_objeto
              if (imageId) {
                const assignamentObj = {
                  id_imagen: imageId,
                  id_producto: this.productId
                }
                const assignamentResponse = await this.postObj('/productos/add-imagen-producto', assignamentObj, 'asignar')

                if (assignamentResponse) {
                  this.alertText = "Imagen asignada exitosamente"
                } else {
                  this.warnings.push('No se pudo asignar la imagen a producto')
                }
              }
            } else {
              this.warnings.push('No se pudo crear la imágen')
            }
          }
        }
      } catch (error) {
        console.error('Error al intentar subir, crear o asignar imagen a producto', error);
      }
    },
    /** 
     * Este metodo se usará cuando se seleccione subir un
     * documento en vez de asignar alguno de los existentes
    */
    async createAndAssignDocsToProduct() {
      /** Para asignar un documento a un producto se hace lo siguiente:
       *  Por cada archivo seleccionado
       *  - Subir archivo
       *  - Crear documento
       *  - Asignar documento a producto
       * 
       */
      try {
        const documents = [...this.docsObj.file]
        //const names = [...this.docsObj.fileName]
        for (const docFile of documents) {
          // Subir archivo
          const docToUpload = {
            file: docFile
          }
          const uploadResponse = await this.uploadFile(docToUpload)
          if (uploadResponse.e) {
            this.warnings.push('No se pudo subir el documento ' + uploadResponse.e.join(', '))
          } else {
            // Crear documento
            const docName = uploadResponse.archivos[0].nombre_archivo
            const docObj = {
              nombre_documento: docName,
              url_documento: docName,
              id_institucion_educativa: this.institutionId,
              id_autor: this.userData.id
            }
            const docResponse = await this.postObj('/productos/crear-documento-producto', docObj)

            if (docResponse) {
              // Asignar documento a producto
              const docId = docResponse.id_objeto
              if (docId) {
                const assignamentObj = {
                  id_documento: docId,
                  id_producto: this.productId
                }
                const assignamentResponse = await this.postObj('/productos/add-documento-producto', assignamentObj, 'asignar')

                if (assignamentResponse) {
                  this.alertText = "Documento asignado exitosamente"
                } else {
                  this.warnings.push('No se pudo asignar el documento a producto')
                }
              }
            } else {
              this.warnings.push('No se pudo crear el documento')
            }
          }
        }
      } catch (error) {
        console.error('Error al intentar subir, crear o asignar documento a producto', error);
      }
    },
    async assignTaxesToProduct(taxesToAssign) {
      try {
        for (const tax of taxesToAssign) {
          const taxObj = {
            id_impuesto: tax,
            id_producto: this.productId
          }

          const assignamentResponse = await this.postObj('/productos/add-impuesto-producto', taxObj, 'asignar')
          if (assignamentResponse) {
            this.alertText = "Impuesto asignado exitosamente"
          } else {
            this.warnings.push('No se pudo asignar el impuesto')
          }
        }
      } catch (error) {
        console.error('Error al intentar asignar impuestos a producto');
      }
    },
    async assignOptionsToProduct(optionsToAssign) {
      try {
        for (const option of optionsToAssign) {
          const optionObj = {
            id_opcion: option,
            id_producto: this.productId
          }

          const assignamentResponse = await this.postObj('/productos/add-opcion-producto', optionObj, 'asignar')
          if (assignamentResponse) {
            this.alertText = "Opción asignada exitosamente"
          } else {
            this.warnings.push('No se pudo asignar la opción')
          }
        }
      } catch (error) {
        console.error('Error al intentar asignar opciones a producto');
      }
    },
    async assingImagesToProduct(imagesToAssign) {
      try {
        for (const image of imagesToAssign) {
          const assignamentObj = {
            id_imagen: image,
            id_producto: this.productId
          }
          const assignamentResponse = await this.postObj('/productos/add-imagen-producto', assignamentObj, 'asignar')

          if (assignamentResponse) {
            this.alertText = "Imagen asignada exitosamente"
          } else {
            this.warnings.push('No se pudo asignar la imagen ' + image + ' a producto')
          }
        }
      } catch (error) {
        console.error('Error al intentar asignar imágenes a producto',  error)
      }
    },
    async assignDocsToProduct(docsToAssign) {
      try {
        for (const doc of docsToAssign) {
          const assignamentObj = {
            id_documento: doc,
            id_producto: this.productId
          }
          const assignamentResponse = await this.postObj('/productos/add-documento-producto', assignamentObj, 'asignar')

          if (assignamentResponse) {
            this.alertText = "Documento asignado exitosamente"
          } else {
            this.warnings.push('No se pudo asignar el documento a producto')
          }
        }
      } catch (error) {
        console.error('Error al intentar asignar documento a producto', error);
      }
    },
    async removeProductTaxes(taxesToRemove) {
      try {
        for (const tax of taxesToRemove) {
          const taxObj = {
            id_producto: this.productId,
            id_impuesto: tax
          }
          const taxResponse = this.postObj('/productos/remover-impuesto', taxObj, 'Remover')
          if (taxResponse) {
            this.alertText = "Impuesto removido exitosamente"
          } else {
            this.warnings.push('No se pudo remover el impuesto del producto')
          }
        }
      } catch (error) {
        console.error('Error al intentar remover impuestos del producto', error);
      }
    },
    async removeProductOptions(optionsToRemove) {
      try {
        for (const option of optionsToRemove) {
          const optionObj = {
            id_producto: this.productId,
            id_opcion: option
          }
          const optionResponse = this.postObj('/productos/remover-opcion-producto', optionObj, 'Remover')
          if (optionResponse) {
            this.alertText = "Option removida exitosamente"
          } else {
            this.warnings.push('No se pudo remover la opción del producto')
          }
        }
      } catch (error) {
        console.error('Error al intentar remover opciones del producto', error);
      }
    },
    async removeProductImages(imagesToRemove) {
      try {
        for (const image of imagesToRemove) {
          const imageObj = {
            id_producto: this.productId,
            id_imagen: image
          }
          const imageResponse = this.postObj('/productos/remover-imagen', imageObj, 'Remover')
          if (imageResponse) {
            this.alertText = "Imagen removida exitosamente"
          } else {
            this.warnings.push('No se pudo remover la imagen del producto')
          }
        }
      } catch (error) {
        console.error('Error al intentar remover opciones del producto', error);
      }
    },
    async removeProductDocuments(docsToRemove) {
      try {
        for (const doc of docsToRemove) {
          const docObj = {
            id_producto: this.productId,
            id_documento: doc
          }
          const imageResponse = this.postObj('/productos/remover-documento', docObj, 'Remover')
          if (imageResponse) {
            this.alertText = "Documento removido exitosamente"
          } else {
            this.warnings.push('No se pudo remover el documento del producto')
          }
        }
      } catch (error) {
        console.error('Error al intentar remover documentos del producto', error);
      }
    },
    async createProduct() {
      try {
        this.alertText = 'Creando producto...'
        const productResponse = await this.postObj('/productos/crear-producto', this.product)

        if (productResponse !== null) {
          this.productId = productResponse.id_objeto

          if (this.productId) {
            if (this.distributionPrice) {
              this.alertText = 'Asignando precio'
              await this.createAndAssignPriceToProduct()
            }

            if (this.taxesSelected.length) {
              this.alertText = 'Asignando impuestos'
              await this.assignTaxesToProduct(this.taxesSelected)
            }

            if (this.optionsSelected.length) {
              this.alertText = 'Asignando opciones'
              await this.assignOptionsToProduct(this.optionsSelected)
            }

            // Cuando se seleccione imágenes ya existentes
            if (this.imagesSelected.length) {
              this.alertText = 'Asignando imágenes'
              await this.assingImagesToProduct(this.imagesSelected)
            }
            // Cuando se seleccione desde la computadora del usuario
            if (this.imagesObj && this.imagesObj.file.length) {
              this.alertText = 'Creando imágenes y asignado a producto'
              await this.createAndAssignImagesToProduct()
            }
            
            // Cuando se seleccione documentos ya existentes
            if (this.docsSelected.length) {
              this.alertText = 'Asignando documentos'
              await this.assignDocsToProduct(this.docsSelected)
            }
            // Cuando se seleccione desde la computadora del usuario
            if (this.docsObj && this.docsObj.file.length) {
              this.alertText = 'Creando documentos y asignado a producto'
              await this.createAndAssignDocsToProduct()
            }
            
            // Revisamos que se hayan llenado todos los
            // campos de inventario, si es asi entonces
            // se manda a crear
            this.inventory.id_producto = this.productId
            if (!this.objectHasNulls(this.inventory)) {
              await this.createInventory()
              await this.createInventoryMovement()
            }
          }
        }
      } catch (error) {
        this.errors.push('Error al intentar crear producto')
        console.error('Error al crear producto.', error);
      }
    },
    async updateProduct() {
      try {
        this.alertText = 'Actualizando producto...'
        const productResponse = await this.postObj('/productos/update-product', {
            ...this.product,
            id_categoria_producto: this.product.id_categoria,
            id_subcategoria_producto: this.product.id_subcategoria
          },
          'Actualizar'
        )
        if (productResponse !== null) {
          // Si el objeto de precio es igual a null
          // signica que no se asignó un precio al
          // crearse el producto
          if (this.distributionPriceObj === null) {
          // si en la actualización se especifica un precio
          // se debe crear y asignar
            if (this.distributionPrice) {
              this.alertText = 'Asignando precio'
              await this.createAndAssignPriceToProduct()
            }
          } else {
            // si el objeto de precio es diferente de null
            // se comprueba si hay cambios de monto y moneda
            // para realizar la actualización de precio
            const { precio_distribuidor, moneda } = this.distributionPriceObj
            if (this.distributionPrice !== parseFloat(precio_distribuidor) 
                || this.product.id_moneda !== moneda) {
              this.alertText = 'Actualizando precio de producto'
              await this.updatePrice()
            }
          }

          // remover y/o asignar impuestos
          const taxesToRemove = this.bkTaxesSelected.filter(tax => !this.taxesSelected.includes(tax))
          const taxesToAssign = this.taxesSelected.filter(tax => !this.bkTaxesSelected.includes(tax))
          if (taxesToRemove) {
            this.alertText = 'Removiendo impuestos de producto'
            await this.removeProductTaxes(taxesToRemove)
          }
          if (taxesToAssign) {
            this.alertText = 'Asignando impuestos a producto'
            await this.assignTaxesToProduct(taxesToAssign)
          }

          // remover y/o asignar opciones de producto
          const optionsToRemove = this.bkOptionsSelected.filter(option => !this.optionsSelected.includes(option))
          const optionsToAssign = this.optionsSelected.filter(option => !this.bkOptionsSelected.includes(option))
          if (optionsToRemove) {
            this.alertText = 'Removiendo opción de producto'
            await this.removeProductOptions(optionsToRemove)
          }
          if (optionsToAssign) {
            this.alertText = 'Asignando opciones de productos'
            await this.assignOptionsToProduct(optionsToAssign)
          }

          // remover y/o asignar imágenes
          const imagesToRemove = this.bkImagesSelected.filter(image => !this.imagesSelected.includes(image))
          const imagesToAssign = this.imagesSelected.filter(image => !this.bkImagesSelected.includes(image))
          if (imagesToRemove) {
            this.alertText = 'Removiendo imagenes del producto'
            await this.removeProductImages(imagesToRemove)
          }
          if (imagesToAssign) {
            this.alertText = 'Asignando imágenes al producto'
            await this.assingImagesToProduct(imagesToAssign)
          }
          // Cuando se seleccione desde la computadora del usuario
          if (this.imagesObj && this.imagesObj.file.length) {
            this.alertText = 'Creando imágenes y asignado a producto'
            await this.createAndAssignImagesToProduct()
          }

          // remover y/o asignar documentos
          const docsToRemove = this.bkDocsSelected.filter(doc => !this.docsSelected.includes(doc))
          const docsToAssign = this.docsSelected.filter(doc => !this.bkDocsSelected.includes(doc))
          if (docsToRemove) {
            this.alertText = 'Removiendo documentos del producto'
            await this.removeProductDocuments(docsToRemove)
          }
          if (docsToAssign) {
            this.alertText = 'Asignando documentos al producto'
            await this.assignDocsToProduct(docsToAssign)
          }
          // Cuando se seleccione desde la computadora del usuario
          if (this.docsObj && this.docsObj.file.length) {
            this.alertText = 'Creando documentos y asignado a producto'
            await this.createAndAssignDocsToProduct()
          }

          this.inventory.id_producto = this.productId
          // Revisamos si el producto tiene inventario
          // en cuyo caso comprobamos si se ha introducido
          // un valor en el campo 'Cantidad a agregar',
          // Si es asi entonces se manda a actualizar el
          // inventario y al mismo tiempo se crea un movimiento
          // de inventario
          if (this.hasInventory && this.amountToAddValid) {
            // console.log('Tiene inventario y se a introducido una cantidad a agregar');
            await this.updateInventory()
            await this.createInventoryMovement()
          } else {
            // En caso de que el producto no tenga inventario
            // se comprueba que se hayan llenado todos los
            // campos de inventario, si es asi entonces se
            // manda a crear y tambien se crea el movimiento
            // correspondiente.
            // TODO: se debe comprobar que se haya asignado
            // el precio al producto ya que es necesario para
            // registrar el movimento de inventario
            // console.log('Inventario a actualizar: ', this.inventory);
            // console.log('No tiene inventario. !objectHasNulls -> ', !this.objectHasNulls(this.inventory));
            if (!this.hasInventory && !this.objectHasNulls(this.inventory)) {
              await this.createInventory()
              await this.createInventoryMovement()
            }
          }
        }
      } catch (error) {
        this.errors.push('Error al intentar actualizar producto')
        console.error('Error al intentar actualizar producto.', error);
      }
    },
    async createObjects() {
      try {
        this.loading = true
        this.alertText = 'Cargando...'
        this.showAlert = true
        await this.createProduct()
        
        if (this.errors.length) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = this.successAlertType
          this.alertColor = 'success'
          this.alertText = this.successAlertText
        }
        this.loading = false
      } catch (error) {
        console.error('Error al dar de alta Producto y objetos relacionados', error);
      }
    },
    async updateObjects() {
      try {
        this.loading = true
        this.alertText = 'Cargando...'
        this.showAlert = true
        await this.updateProduct()

        this.loading = false
        if (this.errors.length > 0) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = this.successAlertType
          this.alertColor = 'success'
          this.alertText = this.successAlertText
        }
      } catch (error) {
        this.loading = false
        // console.log('Error al actualizar Productos y objetos relacionados', error);
        this.errors.push('Error al actualizar Productos y objetos relacionados')
        this.alertType = 'error'
        this.alertText = this.errors.join(', ')

      }
    },
    setTabValue(val) {
      this.valueDeterminate = val
    },
    async save() {
      if (this.isNewMode) {
        if (this.$refs.form.validate()) {
          this.createObjects()
        }
      } else {
        this.updateObjects()
      }
    },
    cancel() {
      this.returnToTable()
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType  === 'info') {
        this.returnToTable()
      } else {
        this.closeAlert()
      }
    },
    continueAdding() {
      this.clean()
      this.closeAlert()
    },
    returnToTable() {
      this.$router.replace({name: this.routerName})
    },
    closeAlert() {
      this.errors = []
      this.showAlert = false
    },
    clean() {
      window.scroll({
        top: 50,
        left: 0,
        behavior: 'smooth'
      });
      this.valueDeterminate = 33
      this.productPersonalInfo = {
        segundo_nombre: '',
        numero_identidad: ''
      }
      this.productAddress = {
        numero_interior: ''
      }
      this.productTaxInfo = {}
      this.productId = null
    },
    async fillData() {
      this.productId = this.entity.id
      this.product = {...this.entity}
      this.product.id_marca = this.entity.marca.id
      this.product.id_codigo_sat = this.entity.codigo_sat.id
      this.product.id_unidad_medida = this.entity.unidad_medida.id
      this.product.id_categoria = this.entity.categoria_producto.id
      this.product.id_subcategoria = this.entity.subcategoria_producto.id
      this.product.id_backorder = this.entity.backorder  ? this.entity.backorder.id : null
      this.product.id_institucion_educativa = this.entity.institucion_educativa.id
      this.product.id_autor = this.userData.id

      const priceProduct = this.product.precios.length 
                            ? this.product.precios.find(price => price.distribuidor === this.institutionId) 
                            : null
      this.distributionPriceObj = priceProduct
      this.distributionPrice = priceProduct ? parseFloat(priceProduct.precio_distribuidor) : null
      this.product.id_moneda = priceProduct ? priceProduct.moneda : null

      const taxesId = this.product.impuesto.map(tax => tax.id)
      this.taxesSelected = [...taxesId]
      this.bkTaxesSelected = [...taxesId]

      const optionsId = this.product.opcion_producto.map(option => option.id)
      this.optionsSelected = [...optionsId]
      this.bkOptionsSelected = [...optionsId]
      
      const imagesId = this.product.imagenes.map(image => image.id)
      this.imagesSelected = [...imagesId]
      this.bkImagesSelected = [...imagesId]

      const docsId = this.product.documentos.map(doc => doc.id)
      this.docsSelected = [...docsId]
      this.bkDocsSelected = [...docsId]

      if (this.product.inventario !== null) {
        this.inventory = {...this.product.inventario}
        this.inventory.id_producto = this.productId
        this.inventory.id_opcion_producto = this.inventory.opcion_producto.id
        this.inventory.id_almacen = this.inventory.almacen.id
        this.inventory.id_unidad_medida = this.inventory.unidad_medida.id
        this.inventory.id_institucion_educativa = this.inventory.institucion_educativa.id
      } else {
        this.inventory.id_institucion_educativa = this.institutionId
        this.inventory.id_autor = this.userData.id
      }
    },
    /** Metodos para guardar imágenes */
    saveImagesSelected(imagesObj) {
      this.imagesObj = imagesObj
      // console.log('Imagenes seleccionadas', imagesObj);
    },
    clearImages() {
      this.imagesObj = null
    },
    /** Metodos para guardar documentos */
    saveDocsSelected(docsObj) {
      this.docsObj = docsObj
      // console.log('Documentos seleccionados', docsObj);
    },
    clearDocs() {
      this.docsObj = null
    },
    activeItems(array) {
      return array.filter(item => item.estatus_sistema === true)
    }
  }
}
</script>

<style>

</style>